export class Employee {

    public userStatus:string;
    public userDate:Date;

    public active_window:string;
    public diff: string;

    public chart_emp_name: string;
    public tot_hours: number;

    
    // configuration master
    public empcode:number;
    public name:string;
    public interval: string;
    public count:string;
    public module:string;
    public userName:string;

    //dataconfiguration master
    public looptime:number;
    public moduleid:string;


    //Employee Details
    public code:number;
    public dte:string;
    public checkin:string;
    public checkout:string;
    public total_hours:string
    public checkout_date:string;
    public department:string;
    public project:string;

//****************************************************************************//
    public first_name:string;
    public middel_name:string;
    public last_name:string;
    public dob:string;
    public gender_id:string;    
    public marital_status:string;
    public blood_group_id:string;
    public mobile_no1:string;
    public mobile_no2:string;
    public personal_email:string;
    public official_email:string;
    public doj:string;
    public identity_mark:string;
    public status_id:string;
    public id :string;
    //Address Details
    public address_type_id:string;
    public address1:string;
    public address2:string;
    public address3:string;
    public state_id:string;
    public pincode:string;
    public country_id:string;


    //Education Details
    public empid:string;
    public qualification_id:string;
    public institution_name:string;
    public year_started:string;
    public year_completed:string;
    public percentage:string;
    public remarks:string;
    

    //Bank Details
    public account_name:string;
    public account_no:string;
    public ifsc_code:string;
    public bank_id:string;
    public branch_name:string;
    public photo:string;        

    //Family Details
    public member_name:string;
    public relationship:string;
    public contact_no:string;

    //Emergency Details
    public contact_person_name:string;
   // public relationship:string;
  //  public contact_no:string;

    //Health Details
    public disease_details:string;
    //public remarks:string;


//#############################################
//Department Details
public department_id:number;

//Designation Details
public designation_id:number;

//Salary Details
public ctc_per_annum:number;
public ctc_per_month:number;
public gross_salary:number;
public net_salary:number;

//Work Location Details
public location:string;
public location_remarks:string;
   
//Remarks Details
public rem_remarks:string;
public rem_description:string;

//Attachment Details
public document_type_id:number;
public doc_no:string;
public doc_name:string;
public doc_ref:string;
public doc_remarks:string;
public doc:string;
public ext:string;
public file_path:string;

educationArray: Education[];
familyArray: Family[];
emergencyArray: Emergency[];
healthArray: Health[];
hobbyArray: Hobbies[]; 
   
}

export class Education{

    public code: string;
    public qualification_id:string;
    public institution_name:string;
    public year_started:string;
    public year_completed:string;
    public percentage:string;    
    public remarks:string;
    
}

export class Family{
    public id: number;
    public member_name:string;
    public relationship:string;
    public contact_no:string;
}

export class Emergency{
    public id: number;
    public contact_person_name:string;
    public relationship:string;
    public contact_no:string;
}

export class Health{
    public id: number;
    public disease_details:string;
    public remarks:string;

}


export class Hobbies{

    public id: number;
    public code: string;
    public hobby_name:string;
    public remarks:string;
    
} 
   
  